var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{on:{"mouseup":_vm.saveCoordinates,"click":_vm.openMenu}},[(this.$store.state.currentDocument.inputType == 0)?_c('div',_vm._l((Object.entries(this.document)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{key:key,staticClass:"mb-2",attrs:{"id":key}},_vm._l((Object.entries(value)),function(ref){
var attKey = ref[0];
var attValue = ref[1];
return _c('div',{key:attKey,attrs:{"id":attKey}},[_c('AnnotatedText',{attrs:{"text":attValue,"conversation":key,"attribute":attKey,"annotations":_vm.filterAnnotations(key, attKey),"getAnnotationColor":_vm.getAnnotationColor,"getSpanClasses":_vm.getSpanClasses,"spanAttributes":_vm.spanAttributes},on:{"editLabelEvent":_vm.editLabel}})],1)}),0)}),0):(this.$store.state.currentDocument.inputType == 1)?_c('div',_vm._l((Object.entries(this.document)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],key:key,attrs:{"id":key}},[_c('h2',[_vm._v(_vm._s("Interview #" + key))]),_c('br'),_vm._l((Object.entries(value)),function(ref){
var attKey = ref[0];
var attValue = ref[1];
return _c('div',{key:attKey,attrs:{"id":attKey}},[_c('h3',[_vm._v(_vm._s(attKey.split('_')[0]))]),_c('br'),_c('AnnotatedText',{attrs:{"text":attValue,"conversation":key,"attribute":attKey,"annotations":_vm.filterAnnotations(key, attKey),"getAnnotationColor":_vm.getAnnotationColor,"getSpanClasses":_vm.getSpanClasses,"spanAttributes":_vm.spanAttributes},on:{"editLabelEvent":_vm.editLabel}}),_c('br')],1)})],2)}),0):(this.$store.state.currentDocument.inputType == 2)?_c('div',_vm._l((Object.entries(this.document)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],key:key,attrs:{"id":key}},[_c('h2',[_vm._v(_vm._s(key))]),_c('br'),_vm._l((Object.entries(value)),function(ref){
var attKey = ref[0];
var attValue = ref[1];
return _c('div',{key:attKey,attrs:{"id":attKey}},[_c('h3',[_vm._v(_vm._s(attKey))]),_c('br'),_c('AnnotatedText',{attrs:{"text":attValue,"conversation":key,"attribute":attKey,"annotations":_vm.filterAnnotations(key, attKey),"getAnnotationColor":_vm.getAnnotationColor,"getSpanClasses":_vm.getSpanClasses,"spanAttributes":_vm.spanAttributes},on:{"editLabelEvent":_vm.editLabel}}),_c('br')],1)})],2)}),0):_vm._e()]),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"close-on-content-click":false,"min-width":"850px","transition":"slide-y-transition","absolute":"","offset-y":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('LabelMenu',{ref:"LabelMenu",attrs:{"InputModel":_vm.model,"currentID":_vm.focusAnnotationID},on:{"newLabel":_vm.updateDefaultSelection,"closeEvent":_vm.closeMenu}})],1),_c('v-snackbar',{attrs:{"timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" "),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Close ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }