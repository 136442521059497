var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-combobox',{ref:"combobox",attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Search for a label or create one","small-chips":"","solo":"","multiple":"","autofocus":"","prepend-inner-icon":"close"},on:_vm._d({"change":_vm.forwardChange,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},[_vm.updateEvent,_vm.listIndexChanged,_vm.closeEvent,_vm.closeInput]),scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create the code ")]),_c('v-chip',{attrs:{"color":_vm.nextColor,"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":("" + (item.color)),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"placeholder":_vm.placeholder,"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)},"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{attrs:{"color":("" + (item.color)),"label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing !== item ? 'mdi-pencil' : 'mdi-check'))])],1)],1)]}},{key:"append-outer",fn:function(){return [_c('RuleInput',{staticClass:"mt-n3",attrs:{"model":_vm.model,"currentID":_vm.currentAnnotationID}})]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }